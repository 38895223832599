define("discourse/plugins/discourse-carousel/discourse/initializers/discourse-carousel", ["exports", "@ember/render-modifiers/modifiers/did-insert", "rsvp", "discourse/lib/api", "discourse/lib/load-script", "discourse/lib/url", "discourse-common/config/environment", "I18n", "discourse/plugins/discourse-carousel/discourse/lib/prevAndNextButtons", "discourse/lib/mobile", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _didInsert, _rsvp, _api, _loadScript, _url, _environment, _I18n, _prevAndNextButtons, _mobile, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* 
  
  FIXME: nazvy temat v .jsonu obsahuje ne-cooked kody z font-awesome, napr. :wave:
  
  Dynamic hiding 
  
  load settings
  
  prioritize based on particular tag
  filter on tags, include
  exclude on tags
  maximum topic shown
  
  */
  /*
  Inspired by: https://github.com/discourse/discourse-algolia/blob/main/assets/javascripts/discourse/api-initializers/discourse-algolia.gjs
  */
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    /* START FUNCTIONS ------------------------------------------------------------------- */function pluginDebug(msg1) {
      if (siteSettings.discourse_carousel_enable_debug_mode) {
        console.log(msg1);
      }
    }
    function initializeCarousel(options1) {
      // options.feedURL,
      // options.maxTopics,
      // options.defaultImage,
      // options.debug,
      // options.excludeTags
      // options.includeTags
      // options.prioritizeTags
      // options.includeTopicsWithoutImage
      //console.dir(options.includeTags);
      //pluginDebug(options.includeTags.length);
      const includeTags1 = options1.includeTags !== "" ? options1.includeTags.split("|") : [];
      const excludeTags1 = options1.excludeTags !== "" ? options1.excludeTags.split("|") : [];
      const prioritizeTags1 = options1.prioritizeTags !== "" ? options1.prioritizeTags.split("|") : [];
      //console.dir(includeTags);
      pluginDebug("Initialize Carousel - options: " + options1);
      const fetchData1 = async url1 => {
        const response1 = await fetch(url1);
        return response1.json();
      };
      const createAvatarCache1 = users1 => {
        var useravatarcache1 = Array();
        for (var i1 = 0; i1 < users1.length; i1++) {
          useravatarcache1[users1[i1].username] = users1[i1].avatar_template.replace("{size}", "25");
          //pluginDebug(useravatarcache[users[i].username]);
        }
        //pluginDebug(useravatarcache.length);
        return useravatarcache1;
      };
      const checkTags1 = (topicTags1, includeTags1) => {
        for (let tag1 of topicTags1) {
          if (includeTags1.includes(tag1)) {
            return true;
          }
        }
        return false;
      };
      function renderTopic1(topic1, avatarcache1, emblaContainer1) {
        const slideDiv1 = document.createElement('div');
        slideDiv1.classList.add('embla__slide');
        // the image
        const image1 = document.createElement('div');
        image1.classList.add('embla__slide__img');
        image1.style.backgroundImage = `url(${topic1.image_url})`;
        slideDiv1.appendChild(image1);
        // clickable link
        let topic_url1 = `${protocol1}://${domain1}:${port1}/t/${topic1.slug}/${topic1.id}/last`;
        slideDiv1.style.cursor = 'pointer'; // Changing the cursor to indicate it's clickable
        slideDiv1.addEventListener('click', function () {
          _url.default.routeTo(topic_url1);
          //pluginDebug('SlideDiv was clicked!'); // Adding the onclick event function
        });
        // the title
        const titleDiv1 = document.createElement('h2');
        titleDiv1.classList.add('embla__slide__title');
        titleDiv1.textContent = topic1.title;
        slideDiv1.appendChild(titleDiv1);
        // the info (author & last updated)
        const infoDiv1 = document.createElement('div');
        infoDiv1.classList.add('embla__slide__info');
        var d1 = new Date(topic1.bumped_at);
        var relative1 = moment(d1, "ddd MMMM DD YYYY hh:mm:ss").fromNow();
        infoDiv1.innerHTML = relative1 + " <img src='" + `${protocol1}://${domain1}:${port1}` + avatarcache1[topic1.last_poster_username] + "' class='avatar' title='" + topic1.last_poster_username + "' alt='" + topic1.last_poster_username + "' />'";
        slideDiv1.appendChild(infoDiv1);
        emblaContainer1.appendChild(slideDiv1);
      }
      const renderTopics1 = (topics1, avatarcache1) => {
        const emblaContainer1 = document.querySelector('.embla__container');
        const maxTopics1 = options1.maxTopics || topics1.length;
        topics1.slice(0, maxTopics1).forEach(topic1 => {
          // options.includeTopicsWithoutImage (siteSettings.discourse_carousel_include_topics_without_image)
          if (!topic1.image_url && siteSettings.discourse_carousel_include_topics_without_image && siteSettings.discourse_carousel_default_image) {
            // backfill with dummy image
            topic1.image_url = siteSettings.discourse_carousel_default_image;
          }
          if (topic1.image_url) {
            renderTopic1(topic1, avatarcache1, emblaContainer1);
          }
        });
      };
      const filterTopics1 = topics1 => {
        /*
        pluginDebug("Filtering starts");
        pluginDebug("includeTags");
        console.dir(includeTags);
        pluginDebug("excludeTags");
        console.dir(excludeTags);
        pluginDebug("prioritizeTags");
        console.dir(prioritizeTags);
        */ // Include only tagged topics (tags selectable via Admin -> Plugins -> Settings)
        var topics1 = topics1.filter(topic1 => {
          // IncludeTags is set => Filter based on tags
          if (includeTags1.length > 0) {
            return checkTags1(topic1.tags, includeTags1);
          }
          return true; // If includeTags array is empty, return true for all topics
        });
        // Exclude tagged topics (tags selectable via Admin -> Plugins -> Settings)
        var topics1 = topics1.filter(topic1 => {
          // ExcludeTags is set => Filter out topics with excluded tags
          if (excludeTags1.length > 0) {
            if (checkTags1(topic1.tags, excludeTags1)) {
              return false; // Topic has excluded tags, so filter it out
            }
          }
          return true; // If excludeTags array is empty or topic does not have excluded tags, return true
        });
        // Change sort order: Prioritize specific tags in topics (tags selectable via Admin -> Plugins -> Settings)
        const prioritizeTopics1 = topics1.filter(topic1 => {
          if (prioritizeTags1.length > 0) {
            return checkTags1(topic1.tags, prioritizeTags1);
          }
          return false; // If prioritizeTags array is empty, do not prioritize any topics
        });
        // Filter out topics that were prioritized
        const remainingTopics1 = topics1.filter(topic1 => !prioritizeTopics1.includes(topic1));
        return [...prioritizeTopics1, ...remainingTopics1];
        //return topics;
      };
      const url1 = options1.feedURL;
      //const url = '/latest.json'
      let domain1, port1, protocol1;
      if (url1.startsWith("http://") || url1.startsWith("https://")) {
        const urlObj1 = new URL(url1);
        domain1 = urlObj1.hostname;
        port1 = urlObj1.port;
        protocol1 = urlObj1.protocol.replace(":", "");
      } else {
        domain1 = window.location.hostname;
        port1 = window.location.port;
        protocol1 = window.location.protocol.replace(":", "");
      }
      // start fetching data from a feed
      fetchData1(url1).then(data1 => {
        const avatarcache1 = createAvatarCache1(data1.users);
        pluginDebug("Loaded avatars: " + Array.isArray(avatarcache1));
        pluginDebug(avatarcache1);
        renderTopics1(filterTopics1(data1.topic_list.topics), avatarcache1);
        EmblaCarousel.defaultOptions = {
          loop: true
        };
        const wrap1 = document.querySelector(".embla");
        const viewPort1 = wrap1.querySelector(".embla__viewport");
        const prevBtn1 = wrap1.querySelector(".embla__button--prev");
        const nextBtn1 = wrap1.querySelector(".embla__button--next");
        const embla1 = EmblaCarousel(viewPort1, {
          skipSnaps: false
        });
        const disablePrevAndNextBtns1 = (0, _prevAndNextButtons.disablePrevNextBtns)(prevBtn1, nextBtn1, embla1);
        (0, _prevAndNextButtons.setupPrevNextBtns)(prevBtn1, nextBtn1, embla1);
        embla1.on("select", disablePrevAndNextBtns1);
        embla1.on("init", disablePrevAndNextBtns1);
      }).catch(error1 => console.error(error1));
      return true;
    }
    /* END FUNCTIONS ----------------------------------------------------------------- */ // show carousel on desktop site only
    _mobile.default.init();
    if (_mobile.default.mobileView) return;
    const siteSettings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    const shouldDisplay = () => siteSettings.discourse_carousel_enabled && (!siteSettings.login_required || currentUser);
    let carousel;
    const plugin_test_custom_setting = siteSettings.discourse_carousel_enabled;
    pluginDebug("Discourse Carousel - setting accessible: " + plugin_test_custom_setting);
    function renderCarousel() {
      _rsvp.Promise.all([
      // Load non-module JS script from /public/javascripts (URL in admin settings)
      (0, _loadScript.default)(siteSettings.discourse_carousel_js_lib)]).then(() => {
        document.body.classList.add("carousel-enabled");
        carousel = initializeCarousel({
          feedURL: siteSettings.discourse_carousel_feed_url,
          maxTopics: siteSettings.discourse_carousel_max_topics,
          defaultImage: siteSettings.discourse_carousel_default_image,
          excludeTags: siteSettings.discourse_carousel_exclude_tags,
          includeTags: siteSettings.discourse_carousel_include_tags,
          prioritizeTags: siteSettings.discourse_carousel_prioritize_tags,
          includeTopicsWithoutImage: siteSettings.discourse_carousel_include_topics_without_image,
          debug: (0, _environment.isDevelopment)()
        });
      });
    }
    // outlet for carousel above welcome box: above-main-container
    // outlet for carousel below welcome box: discovery-list-controls-above
    api.renderInOutlet('above-main-container', (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (shouldDisplay)}}
          <!-- Embla -->
          <div class="embla" {{didInsert renderCarousel}}>
            <div class="embla__viewport">
              <div class="embla__container">
              </div>
            </div>
    
            <!-- Buttons -->
            <button class="embla__button embla__button--prev" type="button">
              <svg
                class="embla__button__svg"
                viewBox="137.718 -1.001 366.563 643.999"
              >
                <path
                  d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"
                ></path>
              </svg>
            </button>
            <button class="embla__button embla__button--next" type="button">
              <svg class="embla__button__svg" viewBox="0 0 238.003 238.003">
                <path
                  d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"
                ></path>
              </svg>
            </button>
    
          </div>
        {{/if}}
      
    */
    {
      "id": "N48vmxB/",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],null,null],[[[1,\"      \"],[3,\" Embla \"],[1,\"\\n      \"],[11,0],[24,0,\"embla\"],[4,[32,1],[[32,2]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"embla__viewport\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"embla__container\"],[12],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[3,\" Buttons \"],[1,\"\\n        \"],[10,\"button\"],[14,0,\"embla__button embla__button--prev\"],[14,4,\"button\"],[12],[1,\"\\n          \"],[10,\"svg\"],[14,0,\"embla__button__svg\"],[14,\"viewBox\",\"137.718 -1.001 366.563 643.999\"],[12],[1,\"\\n            \"],[10,\"path\"],[14,\"d\",\"M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"button\"],[14,0,\"embla__button embla__button--next\"],[14,4,\"button\"],[12],[1,\"\\n          \"],[10,\"svg\"],[14,0,\"embla__button__svg\"],[14,\"viewBox\",\"0 0 238.003 238.003\"],[12],[1,\"\\n            \"],[10,\"path\"],[14,\"d\",\"M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-carousel/discourse/initializers/discourse-carousel.js",
      "scope": () => [shouldDisplay, _didInsert.default, renderCarousel],
      "isStrictMode": true
    }), (0, _templateOnly.default)()));
    // only display on specified pathnames (set via Admin -> Plugins -> Settings)
    //console.dir(siteSettings.discourse_carousel_allowed_pathnames);
    const allowedPathnames = siteSettings.discourse_carousel_allowed_pathnames !== "" ? siteSettings.discourse_carousel_allowed_pathnames.split("|") : [];
    //console.dir(allowedPathnames.length);
    api.onPageChange((url1, title1) => {
      //pluginDebug('the page changed to: ' + url + ' and title ' + title);
      //if (url === '/' || url === '/latest') {
      const carousel_element1 = document.querySelector(".embla");
      if (allowedPathnames.length == 0 || allowedPathnames.includes(url1)) {
        pluginDebug("Show Carousel");
        if (carousel_element1) carousel_element1.style.display = "block";
      } else {
        pluginDebug("Hide Carousel");
        if (carousel_element1) carousel_element1.style.display = "none";
      }
    });
  });
});