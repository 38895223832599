define("discourse/plugins/discourse-carousel/discourse/lib/prevAndNextButtons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setupPrevNextBtns = _exports.disablePrevNextBtns = void 0;
  const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
    prevBtn.addEventListener('click', embla.scrollPrev, false);
    nextBtn.addEventListener('click', embla.scrollNext, false);
  };
  _exports.setupPrevNextBtns = setupPrevNextBtns;
  const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
    return () => {
      if (embla.canScrollPrev()) prevBtn.removeAttribute('disabled');else prevBtn.setAttribute('disabled', 'disabled');
      if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');else nextBtn.setAttribute('disabled', 'disabled');
    };
  };
  _exports.disablePrevNextBtns = disablePrevNextBtns;
});